
import { Action } from "vuex-class";
import { Component, Vue, Ref } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit } from "@/components/forms";
import { PublicOffice } from "@/store/modules/public-office.store";

@Component({
    components: {
        FormBase,
        FormInput,
        FormError,
        FormSubmit,
    },
})
export default class PageMagicLogin extends Vue {
    @Action("auth/passwordlessLogin") passwordlessLogin!: (payload: PasswordlessLogin) => Promise<void>;
    @Action("auth/requestPasswordlessLogin") requestPasswordlessLogin!: (payload: RequestPasswordlessLogin) => Promise<void>;

    @Ref() form!: FormClass;

    payload: RequestPasswordlessLogin = {
        email: "",
    };

    token = "";

    showEmail = false;

    showSuccess = false;

    currentOffice: null | PublicOffice = null;

    created() {
        this.currentOffice = Vue.$cookies.get("hubr-office");

        if (this.currentOffice && this.currentOffice.theme.colors.primary) {
            document.documentElement.style.setProperty("--office-500", this.currentOffice.theme.colors.primary);
        }
    }

    async mounted() {
        if (!this.$route.params.token) {
            this.showEmail = true;
            return;
        }

        this.token = this.$route.params.token;

        await this.login();
    }

    async submit(form: FormClass) {
        this.requestPasswordlessLogin(this.payload)
            .then(() => {
                this.showSuccess = true;
                form.reset();
            })
            .catch((errorResponse: ErrorResponse) => {
                this.$errorResponse = errorResponse;
                form.reset();
            });
    }

    async login() {
        this.passwordlessLogin({ token: this.token })
            .then(() => this.$router.push({ name: "home" }))
            .catch((errorResponse: ErrorResponse) => {
                // @ts-ignore
                if (errorResponse && errorResponse.errors && errorResponse.errors.includes("auth.invalid")) {
                    this.showEmail = true;
                }

                this.$errorResponse = errorResponse;
            });
    }
}
